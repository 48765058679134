import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  GraphQLStringOrFloat: any;
  JSON: any;
};

export enum EventEnum {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type Mutation = {
  __typename?: 'Mutation';
  create_comments_item?: Maybe<Comments>;
  create_comments_items: Array<Comments>;
  create_comments_likes_item?: Maybe<Comments_Likes>;
  create_comments_likes_items: Array<Comments_Likes>;
  create_reviews_item?: Maybe<Reviews>;
  create_reviews_items: Array<Reviews>;
  create_reviews_likes_item?: Maybe<Reviews_Likes>;
  create_reviews_likes_items: Array<Reviews_Likes>;
};


export type MutationCreate_Comments_ItemArgs = {
  data: Create_Comments_Input;
};


export type MutationCreate_Comments_ItemsArgs = {
  data?: InputMaybe<Array<Create_Comments_Input>>;
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Comments_Likes_ItemArgs = {
  data: Create_Comments_Likes_Input;
};


export type MutationCreate_Comments_Likes_ItemsArgs = {
  data?: InputMaybe<Array<Create_Comments_Likes_Input>>;
  filter?: InputMaybe<Comments_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Reviews_ItemArgs = {
  data: Create_Reviews_Input;
};


export type MutationCreate_Reviews_ItemsArgs = {
  data?: InputMaybe<Array<Create_Reviews_Input>>;
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Reviews_Likes_ItemArgs = {
  data: Create_Reviews_Likes_Input;
};


export type MutationCreate_Reviews_Likes_ItemsArgs = {
  data?: InputMaybe<Array<Create_Reviews_Likes_Input>>;
  filter?: InputMaybe<Reviews_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Query = {
  __typename?: 'Query';
  comments: Array<Comments>;
  comments_aggregated: Array<Comments_Aggregated>;
  comments_by_id?: Maybe<Comments>;
  comments_likes: Array<Comments_Likes>;
  comments_likes_aggregated: Array<Comments_Likes_Aggregated>;
  comments_likes_by_id?: Maybe<Comments_Likes>;
  companies: Array<Companies>;
  companies_aggregated: Array<Companies_Aggregated>;
  companies_by_id?: Maybe<Companies>;
  reviews: Array<Reviews>;
  reviews_aggregated: Array<Reviews_Aggregated>;
  reviews_by_id?: Maybe<Reviews>;
  reviews_likes: Array<Reviews_Likes>;
  reviews_likes_aggregated: Array<Reviews_Likes_Aggregated>;
  reviews_likes_by_id?: Maybe<Reviews_Likes>;
  users: Array<Users>;
  users_aggregated: Array<Users_Aggregated>;
  users_by_id?: Maybe<Users>;
};


export type QueryCommentsArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_AggregatedArgs = {
  filter?: InputMaybe<Comments_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryComments_LikesArgs = {
  filter?: InputMaybe<Comments_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_Likes_AggregatedArgs = {
  filter?: InputMaybe<Comments_Likes_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_Likes_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryCompaniesArgs = {
  filter?: InputMaybe<Companies_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCompanies_AggregatedArgs = {
  filter?: InputMaybe<Companies_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCompanies_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryReviewsArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReviews_AggregatedArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReviews_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryReviews_LikesArgs = {
  filter?: InputMaybe<Reviews_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReviews_Likes_AggregatedArgs = {
  filter?: InputMaybe<Reviews_Likes_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReviews_Likes_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsers_AggregatedArgs = {
  filter?: InputMaybe<Users_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsers_By_IdArgs = {
  id: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  comments_likes_mutated?: Maybe<Comments_Likes_Mutated>;
  comments_mutated?: Maybe<Comments_Mutated>;
  companies_mutated?: Maybe<Companies_Mutated>;
  directus_files_mutated?: Maybe<Directus_Files_Mutated>;
  reviews_likes_mutated?: Maybe<Reviews_Likes_Mutated>;
  reviews_mutated?: Maybe<Reviews_Mutated>;
  users_mutated?: Maybe<Users_Mutated>;
};


export type SubscriptionComments_Likes_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionComments_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCompanies_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Files_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionReviews_Likes_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionReviews_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionUsers_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type Boolean_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Comments = {
  __typename?: 'comments';
  comments_likes?: Maybe<Array<Maybe<Comments_Likes>>>;
  comments_likes_func?: Maybe<Count_Functions>;
  /** date_created */
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  /** id */
  id: Scalars['ID'];
  review?: Maybe<Reviews>;
  /** status */
  status?: Maybe<Scalars['String']>;
  /** text */
  text: Scalars['String'];
  user?: Maybe<Users>;
};


export type CommentsComments_LikesArgs = {
  filter?: InputMaybe<Comments_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CommentsReviewArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CommentsUserArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Comments_Aggregated = {
  __typename?: 'comments_aggregated';
  avg?: Maybe<Comments_Aggregated_Fields>;
  avgDistinct?: Maybe<Comments_Aggregated_Fields>;
  count?: Maybe<Comments_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Comments_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Comments_Aggregated_Fields>;
  min?: Maybe<Comments_Aggregated_Fields>;
  sum?: Maybe<Comments_Aggregated_Fields>;
  sumDistinct?: Maybe<Comments_Aggregated_Fields>;
};

export type Comments_Aggregated_Count = {
  __typename?: 'comments_aggregated_count';
  /** comments_likes */
  comments_likes?: Maybe<Scalars['Int']>;
  /** date_created */
  date_created?: Maybe<Scalars['Int']>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** review */
  review?: Maybe<Scalars['Int']>;
  /** status */
  status?: Maybe<Scalars['Int']>;
  /** text */
  text?: Maybe<Scalars['Int']>;
  /** user */
  user?: Maybe<Scalars['Int']>;
};

export type Comments_Aggregated_Fields = {
  __typename?: 'comments_aggregated_fields';
  /** id */
  id?: Maybe<Scalars['Float']>;
  /** review */
  review?: Maybe<Scalars['Float']>;
  /** user */
  user?: Maybe<Scalars['Float']>;
};

export type Comments_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Comments_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Comments_Filter>>>;
  comments_likes?: InputMaybe<Comments_Likes_Filter>;
  comments_likes_func?: InputMaybe<Count_Function_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  review?: InputMaybe<Reviews_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  text?: InputMaybe<String_Filter_Operators>;
  user?: InputMaybe<Users_Filter>;
};

export type Comments_Likes = {
  __typename?: 'comments_likes';
  comment?: Maybe<Comments>;
  /** date_created */
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  /** id */
  id: Scalars['ID'];
  user?: Maybe<Users>;
  /** value */
  value: Scalars['Int'];
};


export type Comments_LikesCommentArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Comments_LikesUserArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Comments_Likes_Aggregated = {
  __typename?: 'comments_likes_aggregated';
  avg?: Maybe<Comments_Likes_Aggregated_Fields>;
  avgDistinct?: Maybe<Comments_Likes_Aggregated_Fields>;
  count?: Maybe<Comments_Likes_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Comments_Likes_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Comments_Likes_Aggregated_Fields>;
  min?: Maybe<Comments_Likes_Aggregated_Fields>;
  sum?: Maybe<Comments_Likes_Aggregated_Fields>;
  sumDistinct?: Maybe<Comments_Likes_Aggregated_Fields>;
};

export type Comments_Likes_Aggregated_Count = {
  __typename?: 'comments_likes_aggregated_count';
  /** comment */
  comment?: Maybe<Scalars['Int']>;
  /** date_created */
  date_created?: Maybe<Scalars['Int']>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** user */
  user?: Maybe<Scalars['Int']>;
  /** value */
  value?: Maybe<Scalars['Int']>;
};

export type Comments_Likes_Aggregated_Fields = {
  __typename?: 'comments_likes_aggregated_fields';
  /** comment */
  comment?: Maybe<Scalars['Float']>;
  /** id */
  id?: Maybe<Scalars['Float']>;
  /** user */
  user?: Maybe<Scalars['Float']>;
  /** value */
  value?: Maybe<Scalars['Float']>;
};

export type Comments_Likes_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Comments_Likes_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Comments_Likes_Filter>>>;
  comment?: InputMaybe<Comments_Filter>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  user?: InputMaybe<Users_Filter>;
  value?: InputMaybe<Number_Filter_Operators>;
};

export type Comments_Likes_Mutated = {
  __typename?: 'comments_likes_mutated';
  data?: Maybe<Comments_Likes>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Comments_Mutated = {
  __typename?: 'comments_mutated';
  data?: Maybe<Comments>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Companies = {
  __typename?: 'companies';
  /** banner */
  banner?: Maybe<Scalars['String']>;
  /** date_created */
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  /** grade */
  grade?: Maybe<Scalars['Float']>;
  /** id */
  id: Scalars['ID'];
  /** logo */
  logo?: Maybe<Scalars['String']>;
  /** name */
  name: Scalars['String'];
  /** rank */
  rank?: Maybe<Scalars['Int']>;
  reviews?: Maybe<Array<Maybe<Reviews>>>;
  reviews_func?: Maybe<Count_Functions>;
  /** seo_description */
  seo_description?: Maybe<Scalars['String']>;
  /** seo_h1 */
  seo_h1?: Maybe<Scalars['String']>;
  /** seo_keywords */
  seo_keywords?: Maybe<Scalars['String']>;
  /** seo_title */
  seo_title?: Maybe<Scalars['String']>;
  /** slug */
  slug: Scalars['String'];
  /** sort */
  sort?: Maybe<Scalars['Int']>;
  /** status */
  status?: Maybe<Scalars['String']>;
  /** tags */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  users?: Maybe<Array<Maybe<Users>>>;
  users_func?: Maybe<Count_Functions>;
};


export type CompaniesReviewsArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CompaniesUsersArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Companies_Aggregated = {
  __typename?: 'companies_aggregated';
  avg?: Maybe<Companies_Aggregated_Fields>;
  avgDistinct?: Maybe<Companies_Aggregated_Fields>;
  count?: Maybe<Companies_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Companies_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Companies_Aggregated_Fields>;
  min?: Maybe<Companies_Aggregated_Fields>;
  sum?: Maybe<Companies_Aggregated_Fields>;
  sumDistinct?: Maybe<Companies_Aggregated_Fields>;
};

export type Companies_Aggregated_Count = {
  __typename?: 'companies_aggregated_count';
  /** banner */
  banner?: Maybe<Scalars['Int']>;
  /** date_created */
  date_created?: Maybe<Scalars['Int']>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Int']>;
  /** grade */
  grade?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** logo */
  logo?: Maybe<Scalars['Int']>;
  /** name */
  name?: Maybe<Scalars['Int']>;
  /** rank */
  rank?: Maybe<Scalars['Int']>;
  /** reviews */
  reviews?: Maybe<Scalars['Int']>;
  /** seo_description */
  seo_description?: Maybe<Scalars['Int']>;
  /** seo_h1 */
  seo_h1?: Maybe<Scalars['Int']>;
  /** seo_keywords */
  seo_keywords?: Maybe<Scalars['Int']>;
  /** seo_title */
  seo_title?: Maybe<Scalars['Int']>;
  /** slug */
  slug?: Maybe<Scalars['Int']>;
  /** sort */
  sort?: Maybe<Scalars['Int']>;
  /** status */
  status?: Maybe<Scalars['Int']>;
  /** tags */
  tags?: Maybe<Scalars['Int']>;
  /** users */
  users?: Maybe<Scalars['Int']>;
};

export type Companies_Aggregated_Fields = {
  __typename?: 'companies_aggregated_fields';
  /** grade */
  grade?: Maybe<Scalars['Float']>;
  /** id */
  id?: Maybe<Scalars['Float']>;
  /** rank */
  rank?: Maybe<Scalars['Float']>;
  /** sort */
  sort?: Maybe<Scalars['Float']>;
};

export type Companies_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Companies_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Companies_Filter>>>;
  banner?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  grade?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  logo?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  rank?: InputMaybe<Number_Filter_Operators>;
  reviews?: InputMaybe<Reviews_Filter>;
  reviews_func?: InputMaybe<Count_Function_Filter_Operators>;
  seo_description?: InputMaybe<String_Filter_Operators>;
  seo_h1?: InputMaybe<String_Filter_Operators>;
  seo_keywords?: InputMaybe<String_Filter_Operators>;
  seo_title?: InputMaybe<String_Filter_Operators>;
  slug?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  users?: InputMaybe<Users_Filter>;
  users_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Companies_Mutated = {
  __typename?: 'companies_mutated';
  data?: Maybe<Companies>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Count_Function_Filter_Operators = {
  count?: InputMaybe<Number_Filter_Operators>;
};

export type Count_Functions = {
  __typename?: 'count_functions';
  count?: Maybe<Scalars['Int']>;
};

export type Create_Comments_Input = {
  recaptcha_token?: InputMaybe<Scalars['String']>;
  /** review */
  review: Scalars['Int'];
  /** status */
  status?: InputMaybe<Scalars['String']>;
  /** text */
  text: Scalars['String'];
  tg_payload?: InputMaybe<Scalars['JSON']>;
  /** user */
  user: Scalars['Int'];
};

export type Create_Comments_Likes_Input = {
  /** comment */
  comment: Scalars['Int'];
  recaptcha_token?: InputMaybe<Scalars['String']>;
  tg_payload?: InputMaybe<Scalars['JSON']>;
  /** user */
  user: Scalars['Int'];
  /** value */
  value: Scalars['Int'];
};

export type Create_Reviews_Input = {
  /** company */
  company: Scalars['Int'];
  /** grade */
  grade: Scalars['Int'];
  /** name */
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  /** text */
  text: Scalars['String'];
  tg_payload?: InputMaybe<Scalars['JSON']>;
  /** user */
  user: Scalars['Int'];
};

export type Create_Reviews_Likes_Input = {
  recaptcha_token?: InputMaybe<Scalars['String']>;
  /** review */
  review: Scalars['Int'];
  tg_payload?: InputMaybe<Scalars['JSON']>;
  /** user */
  user: Scalars['Int'];
  /** value */
  value: Scalars['Int'];
};

export type Date_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type Directus_Files = {
  __typename?: 'directus_files';
  id: Scalars['ID'];
};

export type Directus_Files_Mutated = {
  __typename?: 'directus_files_mutated';
  data?: Maybe<Directus_Files>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Number_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _eq?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _gt?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _gte?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _lt?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _lte?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _neq?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Reviews = {
  __typename?: 'reviews';
  /** closed */
  closed?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Maybe<Comments>>>;
  comments_func?: Maybe<Count_Functions>;
  company?: Maybe<Companies>;
  /** date_created */
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  /** grade */
  grade: Scalars['Int'];
  /** id */
  id: Scalars['ID'];
  /** name */
  name: Scalars['String'];
  reviews_likes?: Maybe<Array<Maybe<Reviews_Likes>>>;
  reviews_likes_func?: Maybe<Count_Functions>;
  /** status */
  status?: Maybe<Scalars['String']>;
  /** text */
  text: Scalars['String'];
  user?: Maybe<Users>;
};


export type ReviewsCommentsArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReviewsCompanyArgs = {
  filter?: InputMaybe<Companies_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReviewsReviews_LikesArgs = {
  filter?: InputMaybe<Reviews_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReviewsUserArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Reviews_Aggregated = {
  __typename?: 'reviews_aggregated';
  avg?: Maybe<Reviews_Aggregated_Fields>;
  avgDistinct?: Maybe<Reviews_Aggregated_Fields>;
  count?: Maybe<Reviews_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Reviews_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Reviews_Aggregated_Fields>;
  min?: Maybe<Reviews_Aggregated_Fields>;
  sum?: Maybe<Reviews_Aggregated_Fields>;
  sumDistinct?: Maybe<Reviews_Aggregated_Fields>;
};

export type Reviews_Aggregated_Count = {
  __typename?: 'reviews_aggregated_count';
  /** closed */
  closed?: Maybe<Scalars['Int']>;
  /** comments */
  comments?: Maybe<Scalars['Int']>;
  /** company */
  company?: Maybe<Scalars['Int']>;
  /** date_created */
  date_created?: Maybe<Scalars['Int']>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Int']>;
  /** grade */
  grade?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** name */
  name?: Maybe<Scalars['Int']>;
  /** reviews_likes */
  reviews_likes?: Maybe<Scalars['Int']>;
  /** status */
  status?: Maybe<Scalars['Int']>;
  /** text */
  text?: Maybe<Scalars['Int']>;
  /** user */
  user?: Maybe<Scalars['Int']>;
};

export type Reviews_Aggregated_Fields = {
  __typename?: 'reviews_aggregated_fields';
  /** company */
  company?: Maybe<Scalars['Float']>;
  /** grade */
  grade?: Maybe<Scalars['Float']>;
  /** id */
  id?: Maybe<Scalars['Float']>;
  /** user */
  user?: Maybe<Scalars['Float']>;
};

export type Reviews_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Reviews_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Reviews_Filter>>>;
  closed?: InputMaybe<Boolean_Filter_Operators>;
  comments?: InputMaybe<Comments_Filter>;
  comments_func?: InputMaybe<Count_Function_Filter_Operators>;
  company?: InputMaybe<Companies_Filter>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  grade?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  reviews_likes?: InputMaybe<Reviews_Likes_Filter>;
  reviews_likes_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  text?: InputMaybe<String_Filter_Operators>;
  user?: InputMaybe<Users_Filter>;
};

export type Reviews_Likes = {
  __typename?: 'reviews_likes';
  /** date_created */
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  /** id */
  id: Scalars['ID'];
  review?: Maybe<Reviews>;
  user?: Maybe<Users>;
  /** value */
  value: Scalars['Int'];
};


export type Reviews_LikesReviewArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Reviews_LikesUserArgs = {
  filter?: InputMaybe<Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Reviews_Likes_Aggregated = {
  __typename?: 'reviews_likes_aggregated';
  avg?: Maybe<Reviews_Likes_Aggregated_Fields>;
  avgDistinct?: Maybe<Reviews_Likes_Aggregated_Fields>;
  count?: Maybe<Reviews_Likes_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Reviews_Likes_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Reviews_Likes_Aggregated_Fields>;
  min?: Maybe<Reviews_Likes_Aggregated_Fields>;
  sum?: Maybe<Reviews_Likes_Aggregated_Fields>;
  sumDistinct?: Maybe<Reviews_Likes_Aggregated_Fields>;
};

export type Reviews_Likes_Aggregated_Count = {
  __typename?: 'reviews_likes_aggregated_count';
  /** date_created */
  date_created?: Maybe<Scalars['Int']>;
  /** date_updated */
  date_updated?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  /** review */
  review?: Maybe<Scalars['Int']>;
  /** user */
  user?: Maybe<Scalars['Int']>;
  /** value */
  value?: Maybe<Scalars['Int']>;
};

export type Reviews_Likes_Aggregated_Fields = {
  __typename?: 'reviews_likes_aggregated_fields';
  /** id */
  id?: Maybe<Scalars['Float']>;
  /** review */
  review?: Maybe<Scalars['Float']>;
  /** user */
  user?: Maybe<Scalars['Float']>;
  /** value */
  value?: Maybe<Scalars['Float']>;
};

export type Reviews_Likes_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Reviews_Likes_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Reviews_Likes_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  review?: InputMaybe<Reviews_Filter>;
  user?: InputMaybe<Users_Filter>;
  value?: InputMaybe<Number_Filter_Operators>;
};

export type Reviews_Likes_Mutated = {
  __typename?: 'reviews_likes_mutated';
  data?: Maybe<Reviews_Likes>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type Reviews_Mutated = {
  __typename?: 'reviews_mutated';
  data?: Maybe<Reviews>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']>;
  _empty?: InputMaybe<Scalars['Boolean']>;
  _ends_with?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['String']>;
  _icontains?: InputMaybe<Scalars['String']>;
  _iends_with?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _istarts_with?: InputMaybe<Scalars['String']>;
  _ncontains?: InputMaybe<Scalars['String']>;
  _nempty?: InputMaybe<Scalars['Boolean']>;
  _nends_with?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _niends_with?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nistarts_with?: InputMaybe<Scalars['String']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _nstarts_with?: InputMaybe<Scalars['String']>;
  _null?: InputMaybe<Scalars['Boolean']>;
  _starts_with?: InputMaybe<Scalars['String']>;
};

export type Users = {
  __typename?: 'users';
  /** blocked */
  blocked?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Maybe<Comments>>>;
  comments_func?: Maybe<Count_Functions>;
  comments_likes?: Maybe<Array<Maybe<Comments_Likes>>>;
  comments_likes_func?: Maybe<Count_Functions>;
  company?: Maybe<Companies>;
  /** id */
  id: Scalars['ID'];
  photo_url?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<Maybe<Reviews>>>;
  reviews_func?: Maybe<Count_Functions>;
  reviews_likes?: Maybe<Array<Maybe<Reviews_Likes>>>;
  reviews_likes_func?: Maybe<Count_Functions>;
  username: Scalars['String'];
};


export type UsersCommentsArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersComments_LikesArgs = {
  filter?: InputMaybe<Comments_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersCompanyArgs = {
  filter?: InputMaybe<Companies_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersReviewsArgs = {
  filter?: InputMaybe<Reviews_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersReviews_LikesArgs = {
  filter?: InputMaybe<Reviews_Likes_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Users_Aggregated = {
  __typename?: 'users_aggregated';
  avg?: Maybe<Users_Aggregated_Fields>;
  avgDistinct?: Maybe<Users_Aggregated_Fields>;
  count?: Maybe<Users_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Users_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Users_Aggregated_Fields>;
  min?: Maybe<Users_Aggregated_Fields>;
  sum?: Maybe<Users_Aggregated_Fields>;
  sumDistinct?: Maybe<Users_Aggregated_Fields>;
};

export type Users_Aggregated_Count = {
  __typename?: 'users_aggregated_count';
  /** blocked */
  blocked?: Maybe<Scalars['Int']>;
  /** comments */
  comments?: Maybe<Scalars['Int']>;
  /** comments_likes */
  comments_likes?: Maybe<Scalars['Int']>;
  /** company */
  company?: Maybe<Scalars['Int']>;
  /** id */
  id?: Maybe<Scalars['Int']>;
  photo_url?: Maybe<Scalars['Int']>;
  /** reviews */
  reviews?: Maybe<Scalars['Int']>;
  /** reviews_likes */
  reviews_likes?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['Int']>;
};

export type Users_Aggregated_Fields = {
  __typename?: 'users_aggregated_fields';
  /** company */
  company?: Maybe<Scalars['Float']>;
  /** id */
  id?: Maybe<Scalars['Float']>;
};

export type Users_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Users_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Users_Filter>>>;
  blocked?: InputMaybe<Boolean_Filter_Operators>;
  comments?: InputMaybe<Comments_Filter>;
  comments_func?: InputMaybe<Count_Function_Filter_Operators>;
  comments_likes?: InputMaybe<Comments_Likes_Filter>;
  comments_likes_func?: InputMaybe<Count_Function_Filter_Operators>;
  company?: InputMaybe<Companies_Filter>;
  id?: InputMaybe<Number_Filter_Operators>;
  photo_url?: InputMaybe<String_Filter_Operators>;
  reviews?: InputMaybe<Reviews_Filter>;
  reviews_func?: InputMaybe<Count_Function_Filter_Operators>;
  reviews_likes?: InputMaybe<Reviews_Likes_Filter>;
  reviews_likes_func?: InputMaybe<Count_Function_Filter_Operators>;
  username?: InputMaybe<String_Filter_Operators>;
};

export type Users_Mutated = {
  __typename?: 'users_mutated';
  data?: Maybe<Users>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID'];
};

export type CompaniesFieldQueryVariables = Exact<{
  filter?: InputMaybe<Companies_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CompaniesFieldQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'companies', id: string, name: string, slug: string }> };

export type CatalogCompaniesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CatalogCompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'companies', id: string, logo?: string | null, name: string, slug: string }> };

export type CompanyBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;


export type CompanyBySlugQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'companies', id: string, status?: string | null, sort?: number | null, name: string, logo?: string | null, banner?: string | null, slug: string, dateCreated?: any | null, rating?: number | null, position?: number | null, title?: string | null, description?: string | null }> };

export type CompaniesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesCountQuery = { __typename?: 'Query', companies_aggregated: Array<{ __typename?: 'companies_aggregated', count?: { __typename?: 'companies_aggregated_count', id?: number | null } | null }> };

export type LikesByReviewIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type LikesByReviewIdQuery = { __typename?: 'Query', reviews_likes_aggregated: Array<{ __typename?: 'reviews_likes_aggregated', count?: { __typename?: 'reviews_likes_aggregated_count', value?: number | null } | null }> };

export type DislikesByReviewIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type DislikesByReviewIdQuery = { __typename?: 'Query', reviews_likes_aggregated: Array<{ __typename?: 'reviews_likes_aggregated', count?: { __typename?: 'reviews_likes_aggregated_count', value?: number | null } | null }> };

export type LikesByCommentIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type LikesByCommentIdQuery = { __typename?: 'Query', comments_likes_aggregated: Array<{ __typename?: 'comments_likes_aggregated', count?: { __typename?: 'comments_likes_aggregated_count', value?: number | null } | null }> };

export type DislikesByCommentIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type DislikesByCommentIdQuery = { __typename?: 'Query', comments_likes_aggregated: Array<{ __typename?: 'comments_likes_aggregated', count?: { __typename?: 'comments_likes_aggregated_count', value?: number | null } | null }> };

export type ReviewsByCompanyIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ReviewsByCompanyIdQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'reviews', id: string, status?: string | null, name: string, date_created?: any | null, text: string, closed?: boolean | null, grade: number, company?: { __typename?: 'companies', id: string, logo?: string | null, slug: string, name: string } | null, user?: { __typename?: 'users', id: string, username: string, photo_url?: string | null } | null }> };

export type PositiveReviewsByCompanyIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type PositiveReviewsByCompanyIdQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'reviews', id: string, status?: string | null, name: string, date_created?: any | null, text: string, closed?: boolean | null, grade: number, company?: { __typename?: 'companies', id: string, logo?: string | null, slug: string, name: string } | null, user?: { __typename?: 'users', id: string, username: string, photo_url?: string | null } | null }> };

export type NegativeReviewsByCompanyIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type NegativeReviewsByCompanyIdQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'reviews', id: string, status?: string | null, name: string, date_created?: any | null, text: string, closed?: boolean | null, grade: number, company?: { __typename?: 'companies', id: string, logo?: string | null, slug: string, name: string } | null, user?: { __typename?: 'users', id: string, username: string, photo_url?: string | null } | null }> };

export type CommentsByReviewIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type CommentsByReviewIdQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'comments', id: string, text: string, dateCreated?: any | null, user?: { __typename?: 'users', id: string, name: string, avatar?: string | null } | null }> };

export type ReviewsCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type ReviewsCountQuery = { __typename?: 'Query', reviews_aggregated: Array<{ __typename?: 'reviews_aggregated', count?: { __typename?: 'reviews_aggregated_count', id?: number | null } | null }> };

export type PositiveReviewsCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type PositiveReviewsCountQuery = { __typename?: 'Query', reviews_aggregated: Array<{ __typename?: 'reviews_aggregated', count?: { __typename?: 'reviews_aggregated_count', id?: number | null } | null }> };

export type NegativeReviewsCountQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type NegativeReviewsCountQuery = { __typename?: 'Query', reviews_aggregated: Array<{ __typename?: 'reviews_aggregated', count?: { __typename?: 'reviews_aggregated_count', id?: number | null } | null }> };

export type TopCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type TopCompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'companies', id: string, logo?: string | null, slug: string, name: string }> };

export type CompanyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyCountQuery = { __typename?: 'Query', companies_aggregated: Array<{ __typename?: 'companies_aggregated', count?: { __typename?: 'companies_aggregated_count', id?: number | null } | null }> };

export type ReviewsSliderQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewsSliderQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'reviews', id: string, grade: number, name: string, text: string, date_created?: any | null, company?: { __typename?: 'companies', id: string, name: string, logo?: string | null, slug: string } | null, user?: { __typename?: 'users', photo_url?: string | null, id: string, username: string } | null }> };

export type ReviewByIdQueryVariables = Exact<{
  id: Scalars['GraphQLStringOrFloat'];
  slug?: InputMaybe<Scalars['String']>;
}>;


export type ReviewByIdQuery = { __typename?: 'Query', review: Array<{ __typename?: 'companies', id: string, reviews?: Array<{ __typename?: 'reviews', id: string, status?: string | null, text: string, closed?: boolean | null, grade: number, title: string, dateCreated?: any | null, user?: { __typename?: 'users', id: string, name: string, avatar?: string | null } | null, company?: { __typename?: 'companies', id: string, logo?: string | null, slug: string, banner?: string | null, name: string } | null } | null> | null }> };

export type CommentsCountByReviewIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type CommentsCountByReviewIdQuery = { __typename?: 'Query', comments_aggregated: Array<{ __typename?: 'comments_aggregated', count?: { __typename?: 'comments_aggregated_count', id?: number | null } | null }> };

export type ReviewsFeedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ReviewsFeedQuery = { __typename?: 'Query', reviews: Array<{ __typename?: 'reviews', id: string, status?: string | null, name: string, date_created?: any | null, text: string, closed?: boolean | null, grade: number, user?: { __typename?: 'users', id: string, username: string, photo_url?: string | null } | null, company?: { __typename?: 'companies', id: string, logo?: string | null, slug: string, name: string } | null }> };

export type AllReviewsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type AllReviewsCountQuery = { __typename?: 'Query', reviews_aggregated: Array<{ __typename?: 'reviews_aggregated', count?: { __typename?: 'reviews_aggregated_count', id?: number | null } | null }> };

export type ReviewsCountBetweenDatesQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
  endDate?: InputMaybe<Scalars['GraphQLStringOrFloat']>;
}>;


export type ReviewsCountBetweenDatesQuery = { __typename?: 'Query', reviews_aggregated: Array<{ __typename?: 'reviews_aggregated', count?: { __typename?: 'reviews_aggregated_count', id?: number | null } | null }> };

export type CreateReviewMutationVariables = Exact<{
  data: Create_Reviews_Input;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', create_reviews_item?: { __typename?: 'reviews', id: string, text: string } | null };

export type CreateCommentMutationVariables = Exact<{
  data: Create_Comments_Input;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', create_comments_item?: { __typename?: 'comments', id: string, text: string } | null };

export type AddCommentLikeMutationVariables = Exact<{
  data: Create_Comments_Likes_Input;
}>;


export type AddCommentLikeMutation = { __typename?: 'Mutation', create_comments_likes_item?: { __typename?: 'comments_likes', id: string, value: number } | null };

export type AddReviewLikeMutationVariables = Exact<{
  data: Create_Reviews_Likes_Input;
}>;


export type AddReviewLikeMutation = { __typename?: 'Mutation', create_reviews_likes_item?: { __typename?: 'reviews_likes', id: string, value: number } | null };


export const CompaniesFieldDocument = gql`
    query CompaniesField($filter: companies_filter, $limit: Int) {
  companies(filter: $filter, limit: $limit) {
    id
    name
    slug
  }
}
    `;

/**
 * __useCompaniesFieldQuery__
 *
 * To run a query within a React component, call `useCompaniesFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompaniesFieldQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesFieldQuery, CompaniesFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesFieldQuery, CompaniesFieldQueryVariables>(CompaniesFieldDocument, options);
      }
export function useCompaniesFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesFieldQuery, CompaniesFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesFieldQuery, CompaniesFieldQueryVariables>(CompaniesFieldDocument, options);
        }
export type CompaniesFieldQueryHookResult = ReturnType<typeof useCompaniesFieldQuery>;
export type CompaniesFieldLazyQueryHookResult = ReturnType<typeof useCompaniesFieldLazyQuery>;
export type CompaniesFieldQueryResult = Apollo.QueryResult<CompaniesFieldQuery, CompaniesFieldQueryVariables>;
export const CatalogCompaniesDocument = gql`
    query CatalogCompanies($limit: Int) {
  companies(filter: {status: {_eq: "published"}}, limit: $limit) {
    id
    logo
    name
    slug
  }
}
    `;

/**
 * __useCatalogCompaniesQuery__
 *
 * To run a query within a React component, call `useCatalogCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCatalogCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CatalogCompaniesQuery, CatalogCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogCompaniesQuery, CatalogCompaniesQueryVariables>(CatalogCompaniesDocument, options);
      }
export function useCatalogCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogCompaniesQuery, CatalogCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogCompaniesQuery, CatalogCompaniesQueryVariables>(CatalogCompaniesDocument, options);
        }
export type CatalogCompaniesQueryHookResult = ReturnType<typeof useCatalogCompaniesQuery>;
export type CatalogCompaniesLazyQueryHookResult = ReturnType<typeof useCatalogCompaniesLazyQuery>;
export type CatalogCompaniesQueryResult = Apollo.QueryResult<CatalogCompaniesQuery, CatalogCompaniesQueryVariables>;
export const CompanyBySlugDocument = gql`
    query CompanyBySlug($slug: String) {
  companies(filter: {slug: {_eq: $slug}, status: {_eq: "published"}}) {
    id
    status
    sort
    dateCreated: date_created
    name
    logo
    banner
    slug
    rating: grade
    position: rank
    title: seo_title
    description: seo_description
  }
}
    `;

/**
 * __useCompanyBySlugQuery__
 *
 * To run a query within a React component, call `useCompanyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCompanyBySlugQuery(baseOptions?: Apollo.QueryHookOptions<CompanyBySlugQuery, CompanyBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyBySlugQuery, CompanyBySlugQueryVariables>(CompanyBySlugDocument, options);
      }
export function useCompanyBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyBySlugQuery, CompanyBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyBySlugQuery, CompanyBySlugQueryVariables>(CompanyBySlugDocument, options);
        }
export type CompanyBySlugQueryHookResult = ReturnType<typeof useCompanyBySlugQuery>;
export type CompanyBySlugLazyQueryHookResult = ReturnType<typeof useCompanyBySlugLazyQuery>;
export type CompanyBySlugQueryResult = Apollo.QueryResult<CompanyBySlugQuery, CompanyBySlugQueryVariables>;
export const CompaniesCountDocument = gql`
    query CompaniesCount {
  companies_aggregated(filter: {status: {_eq: "published"}}) {
    count {
      id
    }
  }
}
    `;

/**
 * __useCompaniesCountQuery__
 *
 * To run a query within a React component, call `useCompaniesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesCountQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesCountQuery, CompaniesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesCountQuery, CompaniesCountQueryVariables>(CompaniesCountDocument, options);
      }
export function useCompaniesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesCountQuery, CompaniesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesCountQuery, CompaniesCountQueryVariables>(CompaniesCountDocument, options);
        }
export type CompaniesCountQueryHookResult = ReturnType<typeof useCompaniesCountQuery>;
export type CompaniesCountLazyQueryHookResult = ReturnType<typeof useCompaniesCountLazyQuery>;
export type CompaniesCountQueryResult = Apollo.QueryResult<CompaniesCountQuery, CompaniesCountQueryVariables>;
export const LikesByReviewIdDocument = gql`
    query LikesByReviewId($id: GraphQLStringOrFloat) {
  reviews_likes_aggregated(filter: {review: {id: {_eq: $id}}, value: {_eq: 1}}) {
    count {
      value
    }
  }
}
    `;

/**
 * __useLikesByReviewIdQuery__
 *
 * To run a query within a React component, call `useLikesByReviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikesByReviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikesByReviewIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikesByReviewIdQuery(baseOptions?: Apollo.QueryHookOptions<LikesByReviewIdQuery, LikesByReviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LikesByReviewIdQuery, LikesByReviewIdQueryVariables>(LikesByReviewIdDocument, options);
      }
export function useLikesByReviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikesByReviewIdQuery, LikesByReviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LikesByReviewIdQuery, LikesByReviewIdQueryVariables>(LikesByReviewIdDocument, options);
        }
export type LikesByReviewIdQueryHookResult = ReturnType<typeof useLikesByReviewIdQuery>;
export type LikesByReviewIdLazyQueryHookResult = ReturnType<typeof useLikesByReviewIdLazyQuery>;
export type LikesByReviewIdQueryResult = Apollo.QueryResult<LikesByReviewIdQuery, LikesByReviewIdQueryVariables>;
export const DislikesByReviewIdDocument = gql`
    query DislikesByReviewId($id: GraphQLStringOrFloat) {
  reviews_likes_aggregated(filter: {review: {id: {_eq: $id}}, value: {_eq: -1}}) {
    count {
      value
    }
  }
}
    `;

/**
 * __useDislikesByReviewIdQuery__
 *
 * To run a query within a React component, call `useDislikesByReviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDislikesByReviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDislikesByReviewIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDislikesByReviewIdQuery(baseOptions?: Apollo.QueryHookOptions<DislikesByReviewIdQuery, DislikesByReviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DislikesByReviewIdQuery, DislikesByReviewIdQueryVariables>(DislikesByReviewIdDocument, options);
      }
export function useDislikesByReviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DislikesByReviewIdQuery, DislikesByReviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DislikesByReviewIdQuery, DislikesByReviewIdQueryVariables>(DislikesByReviewIdDocument, options);
        }
export type DislikesByReviewIdQueryHookResult = ReturnType<typeof useDislikesByReviewIdQuery>;
export type DislikesByReviewIdLazyQueryHookResult = ReturnType<typeof useDislikesByReviewIdLazyQuery>;
export type DislikesByReviewIdQueryResult = Apollo.QueryResult<DislikesByReviewIdQuery, DislikesByReviewIdQueryVariables>;
export const LikesByCommentIdDocument = gql`
    query LikesByCommentId($id: GraphQLStringOrFloat) {
  comments_likes_aggregated(filter: {comment: {id: {_eq: $id}}, value: {_eq: 1}}) {
    count {
      value
    }
  }
}
    `;

/**
 * __useLikesByCommentIdQuery__
 *
 * To run a query within a React component, call `useLikesByCommentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLikesByCommentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLikesByCommentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikesByCommentIdQuery(baseOptions?: Apollo.QueryHookOptions<LikesByCommentIdQuery, LikesByCommentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LikesByCommentIdQuery, LikesByCommentIdQueryVariables>(LikesByCommentIdDocument, options);
      }
export function useLikesByCommentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LikesByCommentIdQuery, LikesByCommentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LikesByCommentIdQuery, LikesByCommentIdQueryVariables>(LikesByCommentIdDocument, options);
        }
export type LikesByCommentIdQueryHookResult = ReturnType<typeof useLikesByCommentIdQuery>;
export type LikesByCommentIdLazyQueryHookResult = ReturnType<typeof useLikesByCommentIdLazyQuery>;
export type LikesByCommentIdQueryResult = Apollo.QueryResult<LikesByCommentIdQuery, LikesByCommentIdQueryVariables>;
export const DislikesByCommentIdDocument = gql`
    query DislikesByCommentId($id: GraphQLStringOrFloat) {
  comments_likes_aggregated(filter: {comment: {id: {_eq: $id}}, value: {_eq: -1}}) {
    count {
      value
    }
  }
}
    `;

/**
 * __useDislikesByCommentIdQuery__
 *
 * To run a query within a React component, call `useDislikesByCommentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDislikesByCommentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDislikesByCommentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDislikesByCommentIdQuery(baseOptions?: Apollo.QueryHookOptions<DislikesByCommentIdQuery, DislikesByCommentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DislikesByCommentIdQuery, DislikesByCommentIdQueryVariables>(DislikesByCommentIdDocument, options);
      }
export function useDislikesByCommentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DislikesByCommentIdQuery, DislikesByCommentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DislikesByCommentIdQuery, DislikesByCommentIdQueryVariables>(DislikesByCommentIdDocument, options);
        }
export type DislikesByCommentIdQueryHookResult = ReturnType<typeof useDislikesByCommentIdQuery>;
export type DislikesByCommentIdLazyQueryHookResult = ReturnType<typeof useDislikesByCommentIdLazyQuery>;
export type DislikesByCommentIdQueryResult = Apollo.QueryResult<DislikesByCommentIdQuery, DislikesByCommentIdQueryVariables>;
export const ReviewsByCompanyIdDocument = gql`
    query ReviewsByCompanyId($id: GraphQLStringOrFloat, $page: Int, $limit: Int) {
  reviews(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}}
    sort: ["-date_created"]
    limit: $limit
    page: $page
  ) {
    id
    status
    name
    date_created
    text
    closed
    grade
    company {
      id
      logo
      slug
      name
    }
    user {
      id
      username
      photo_url
    }
  }
}
    `;

/**
 * __useReviewsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useReviewsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsByCompanyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useReviewsByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<ReviewsByCompanyIdQuery, ReviewsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsByCompanyIdQuery, ReviewsByCompanyIdQueryVariables>(ReviewsByCompanyIdDocument, options);
      }
export function useReviewsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsByCompanyIdQuery, ReviewsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsByCompanyIdQuery, ReviewsByCompanyIdQueryVariables>(ReviewsByCompanyIdDocument, options);
        }
export type ReviewsByCompanyIdQueryHookResult = ReturnType<typeof useReviewsByCompanyIdQuery>;
export type ReviewsByCompanyIdLazyQueryHookResult = ReturnType<typeof useReviewsByCompanyIdLazyQuery>;
export type ReviewsByCompanyIdQueryResult = Apollo.QueryResult<ReviewsByCompanyIdQuery, ReviewsByCompanyIdQueryVariables>;
export const PositiveReviewsByCompanyIdDocument = gql`
    query PositiveReviewsByCompanyId($id: GraphQLStringOrFloat, $page: Int, $limit: Int) {
  reviews(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}, grade: {_between: [4, 5]}}
    sort: ["-date_created"]
    limit: $limit
    page: $page
  ) {
    id
    status
    name
    date_created
    text
    closed
    grade
    company {
      id
      logo
      slug
      name
    }
    user {
      id
      username
      photo_url
    }
  }
}
    `;

/**
 * __usePositiveReviewsByCompanyIdQuery__
 *
 * To run a query within a React component, call `usePositiveReviewsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositiveReviewsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositiveReviewsByCompanyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePositiveReviewsByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<PositiveReviewsByCompanyIdQuery, PositiveReviewsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositiveReviewsByCompanyIdQuery, PositiveReviewsByCompanyIdQueryVariables>(PositiveReviewsByCompanyIdDocument, options);
      }
export function usePositiveReviewsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositiveReviewsByCompanyIdQuery, PositiveReviewsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositiveReviewsByCompanyIdQuery, PositiveReviewsByCompanyIdQueryVariables>(PositiveReviewsByCompanyIdDocument, options);
        }
export type PositiveReviewsByCompanyIdQueryHookResult = ReturnType<typeof usePositiveReviewsByCompanyIdQuery>;
export type PositiveReviewsByCompanyIdLazyQueryHookResult = ReturnType<typeof usePositiveReviewsByCompanyIdLazyQuery>;
export type PositiveReviewsByCompanyIdQueryResult = Apollo.QueryResult<PositiveReviewsByCompanyIdQuery, PositiveReviewsByCompanyIdQueryVariables>;
export const NegativeReviewsByCompanyIdDocument = gql`
    query NegativeReviewsByCompanyId($id: GraphQLStringOrFloat, $page: Int, $limit: Int) {
  reviews(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}, grade: {_between: [1, 3]}}
    sort: ["-date_created"]
    limit: $limit
    page: $page
  ) {
    id
    status
    name
    date_created
    text
    closed
    grade
    company {
      id
      logo
      slug
      name
    }
    user {
      id
      username
      photo_url
    }
  }
}
    `;

/**
 * __useNegativeReviewsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useNegativeReviewsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNegativeReviewsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNegativeReviewsByCompanyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNegativeReviewsByCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<NegativeReviewsByCompanyIdQuery, NegativeReviewsByCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NegativeReviewsByCompanyIdQuery, NegativeReviewsByCompanyIdQueryVariables>(NegativeReviewsByCompanyIdDocument, options);
      }
export function useNegativeReviewsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NegativeReviewsByCompanyIdQuery, NegativeReviewsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NegativeReviewsByCompanyIdQuery, NegativeReviewsByCompanyIdQueryVariables>(NegativeReviewsByCompanyIdDocument, options);
        }
export type NegativeReviewsByCompanyIdQueryHookResult = ReturnType<typeof useNegativeReviewsByCompanyIdQuery>;
export type NegativeReviewsByCompanyIdLazyQueryHookResult = ReturnType<typeof useNegativeReviewsByCompanyIdLazyQuery>;
export type NegativeReviewsByCompanyIdQueryResult = Apollo.QueryResult<NegativeReviewsByCompanyIdQuery, NegativeReviewsByCompanyIdQueryVariables>;
export const CommentsByReviewIdDocument = gql`
    query CommentsByReviewId($id: GraphQLStringOrFloat, $page: Int, $limit: Int) {
  comments(
    filter: {review: {id: {_eq: $id}}, status: {_eq: "published"}}
    limit: $limit
    page: $page
  ) {
    id
    dateCreated: date_created
    text
    user {
      id
      name: username
      avatar: photo_url
    }
  }
}
    `;

/**
 * __useCommentsByReviewIdQuery__
 *
 * To run a query within a React component, call `useCommentsByReviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsByReviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsByReviewIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCommentsByReviewIdQuery(baseOptions?: Apollo.QueryHookOptions<CommentsByReviewIdQuery, CommentsByReviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsByReviewIdQuery, CommentsByReviewIdQueryVariables>(CommentsByReviewIdDocument, options);
      }
export function useCommentsByReviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsByReviewIdQuery, CommentsByReviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsByReviewIdQuery, CommentsByReviewIdQueryVariables>(CommentsByReviewIdDocument, options);
        }
export type CommentsByReviewIdQueryHookResult = ReturnType<typeof useCommentsByReviewIdQuery>;
export type CommentsByReviewIdLazyQueryHookResult = ReturnType<typeof useCommentsByReviewIdLazyQuery>;
export type CommentsByReviewIdQueryResult = Apollo.QueryResult<CommentsByReviewIdQuery, CommentsByReviewIdQueryVariables>;
export const ReviewsCountDocument = gql`
    query ReviewsCount($id: GraphQLStringOrFloat) {
  reviews_aggregated(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}}
  ) {
    count {
      id
    }
  }
}
    `;

/**
 * __useReviewsCountQuery__
 *
 * To run a query within a React component, call `useReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewsCountQuery(baseOptions?: Apollo.QueryHookOptions<ReviewsCountQuery, ReviewsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsCountQuery, ReviewsCountQueryVariables>(ReviewsCountDocument, options);
      }
export function useReviewsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsCountQuery, ReviewsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsCountQuery, ReviewsCountQueryVariables>(ReviewsCountDocument, options);
        }
export type ReviewsCountQueryHookResult = ReturnType<typeof useReviewsCountQuery>;
export type ReviewsCountLazyQueryHookResult = ReturnType<typeof useReviewsCountLazyQuery>;
export type ReviewsCountQueryResult = Apollo.QueryResult<ReviewsCountQuery, ReviewsCountQueryVariables>;
export const PositiveReviewsCountDocument = gql`
    query PositiveReviewsCount($id: GraphQLStringOrFloat) {
  reviews_aggregated(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}, grade: {_between: [4, 5]}}
  ) {
    count {
      id
    }
  }
}
    `;

/**
 * __usePositiveReviewsCountQuery__
 *
 * To run a query within a React component, call `usePositiveReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositiveReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositiveReviewsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositiveReviewsCountQuery(baseOptions?: Apollo.QueryHookOptions<PositiveReviewsCountQuery, PositiveReviewsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositiveReviewsCountQuery, PositiveReviewsCountQueryVariables>(PositiveReviewsCountDocument, options);
      }
export function usePositiveReviewsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositiveReviewsCountQuery, PositiveReviewsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositiveReviewsCountQuery, PositiveReviewsCountQueryVariables>(PositiveReviewsCountDocument, options);
        }
export type PositiveReviewsCountQueryHookResult = ReturnType<typeof usePositiveReviewsCountQuery>;
export type PositiveReviewsCountLazyQueryHookResult = ReturnType<typeof usePositiveReviewsCountLazyQuery>;
export type PositiveReviewsCountQueryResult = Apollo.QueryResult<PositiveReviewsCountQuery, PositiveReviewsCountQueryVariables>;
export const NegativeReviewsCountDocument = gql`
    query NegativeReviewsCount($id: GraphQLStringOrFloat) {
  reviews_aggregated(
    filter: {company: {id: {_eq: $id}}, status: {_eq: "published"}, grade: {_between: [1, 3]}}
  ) {
    count {
      id
    }
  }
}
    `;

/**
 * __useNegativeReviewsCountQuery__
 *
 * To run a query within a React component, call `useNegativeReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNegativeReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNegativeReviewsCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNegativeReviewsCountQuery(baseOptions?: Apollo.QueryHookOptions<NegativeReviewsCountQuery, NegativeReviewsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NegativeReviewsCountQuery, NegativeReviewsCountQueryVariables>(NegativeReviewsCountDocument, options);
      }
export function useNegativeReviewsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NegativeReviewsCountQuery, NegativeReviewsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NegativeReviewsCountQuery, NegativeReviewsCountQueryVariables>(NegativeReviewsCountDocument, options);
        }
export type NegativeReviewsCountQueryHookResult = ReturnType<typeof useNegativeReviewsCountQuery>;
export type NegativeReviewsCountLazyQueryHookResult = ReturnType<typeof useNegativeReviewsCountLazyQuery>;
export type NegativeReviewsCountQueryResult = Apollo.QueryResult<NegativeReviewsCountQuery, NegativeReviewsCountQueryVariables>;
export const TopCompaniesDocument = gql`
    query TopCompanies {
  companies(
    limit: 10
    sort: ["rank"]
    filter: {status: {_eq: "published"}, logo: {_nnull: true}}
  ) {
    id
    logo
    slug
    name
  }
}
    `;

/**
 * __useTopCompaniesQuery__
 *
 * To run a query within a React component, call `useTopCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<TopCompaniesQuery, TopCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopCompaniesQuery, TopCompaniesQueryVariables>(TopCompaniesDocument, options);
      }
export function useTopCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopCompaniesQuery, TopCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopCompaniesQuery, TopCompaniesQueryVariables>(TopCompaniesDocument, options);
        }
export type TopCompaniesQueryHookResult = ReturnType<typeof useTopCompaniesQuery>;
export type TopCompaniesLazyQueryHookResult = ReturnType<typeof useTopCompaniesLazyQuery>;
export type TopCompaniesQueryResult = Apollo.QueryResult<TopCompaniesQuery, TopCompaniesQueryVariables>;
export const CompanyCountDocument = gql`
    query CompanyCount {
  companies_aggregated(filter: {status: {_eq: "published"}}) {
    count {
      id
    }
  }
}
    `;

/**
 * __useCompanyCountQuery__
 *
 * To run a query within a React component, call `useCompanyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyCountQuery(baseOptions?: Apollo.QueryHookOptions<CompanyCountQuery, CompanyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCountQuery, CompanyCountQueryVariables>(CompanyCountDocument, options);
      }
export function useCompanyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCountQuery, CompanyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCountQuery, CompanyCountQueryVariables>(CompanyCountDocument, options);
        }
export type CompanyCountQueryHookResult = ReturnType<typeof useCompanyCountQuery>;
export type CompanyCountLazyQueryHookResult = ReturnType<typeof useCompanyCountLazyQuery>;
export type CompanyCountQueryResult = Apollo.QueryResult<CompanyCountQuery, CompanyCountQueryVariables>;
export const ReviewsSliderDocument = gql`
    query ReviewsSlider {
  reviews(
    limit: 15
    filter: {status: {_eq: "published"}}
    sort: ["-date_created"]
  ) {
    id
    grade
    name
    text
    date_created
    company {
      id
      name
      logo
      slug
    }
    user {
      photo_url
      id
      username
    }
  }
}
    `;

/**
 * __useReviewsSliderQuery__
 *
 * To run a query within a React component, call `useReviewsSliderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsSliderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsSliderQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewsSliderQuery(baseOptions?: Apollo.QueryHookOptions<ReviewsSliderQuery, ReviewsSliderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsSliderQuery, ReviewsSliderQueryVariables>(ReviewsSliderDocument, options);
      }
export function useReviewsSliderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsSliderQuery, ReviewsSliderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsSliderQuery, ReviewsSliderQueryVariables>(ReviewsSliderDocument, options);
        }
export type ReviewsSliderQueryHookResult = ReturnType<typeof useReviewsSliderQuery>;
export type ReviewsSliderLazyQueryHookResult = ReturnType<typeof useReviewsSliderLazyQuery>;
export type ReviewsSliderQueryResult = Apollo.QueryResult<ReviewsSliderQuery, ReviewsSliderQueryVariables>;
export const ReviewByIdDocument = gql`
    query ReviewById($id: GraphQLStringOrFloat!, $slug: String) {
  review: companies(
    filter: {slug: {_starts_with: $slug}, status: {_eq: "published"}}
  ) {
    id
    reviews(filter: {id: {_eq: $id}}) {
      id
      status
      title: name
      dateCreated: date_created
      text
      closed
      grade
      user {
        id
        name: username
        avatar: photo_url
      }
      company {
        id
        logo
        slug
        banner
        name
      }
    }
  }
}
    `;

/**
 * __useReviewByIdQuery__
 *
 * To run a query within a React component, call `useReviewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReviewByIdQuery(baseOptions: Apollo.QueryHookOptions<ReviewByIdQuery, ReviewByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewByIdQuery, ReviewByIdQueryVariables>(ReviewByIdDocument, options);
      }
export function useReviewByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewByIdQuery, ReviewByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewByIdQuery, ReviewByIdQueryVariables>(ReviewByIdDocument, options);
        }
export type ReviewByIdQueryHookResult = ReturnType<typeof useReviewByIdQuery>;
export type ReviewByIdLazyQueryHookResult = ReturnType<typeof useReviewByIdLazyQuery>;
export type ReviewByIdQueryResult = Apollo.QueryResult<ReviewByIdQuery, ReviewByIdQueryVariables>;
export const CommentsCountByReviewIdDocument = gql`
    query CommentsCountByReviewId($id: GraphQLStringOrFloat) {
  comments_aggregated(
    filter: {status: {_eq: "published"}, review: {id: {_eq: $id}}}
  ) {
    count {
      id
    }
  }
}
    `;

/**
 * __useCommentsCountByReviewIdQuery__
 *
 * To run a query within a React component, call `useCommentsCountByReviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsCountByReviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsCountByReviewIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentsCountByReviewIdQuery(baseOptions?: Apollo.QueryHookOptions<CommentsCountByReviewIdQuery, CommentsCountByReviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsCountByReviewIdQuery, CommentsCountByReviewIdQueryVariables>(CommentsCountByReviewIdDocument, options);
      }
export function useCommentsCountByReviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsCountByReviewIdQuery, CommentsCountByReviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsCountByReviewIdQuery, CommentsCountByReviewIdQueryVariables>(CommentsCountByReviewIdDocument, options);
        }
export type CommentsCountByReviewIdQueryHookResult = ReturnType<typeof useCommentsCountByReviewIdQuery>;
export type CommentsCountByReviewIdLazyQueryHookResult = ReturnType<typeof useCommentsCountByReviewIdLazyQuery>;
export type CommentsCountByReviewIdQueryResult = Apollo.QueryResult<CommentsCountByReviewIdQuery, CommentsCountByReviewIdQueryVariables>;
export const ReviewsFeedDocument = gql`
    query ReviewsFeed($limit: Int, $page: Int) {
  reviews(
    filter: {status: {_eq: "published"}}
    sort: ["-date_created"]
    limit: $limit
    page: $page
  ) {
    id
    status
    name
    date_created
    text
    closed
    grade
    user {
      id
      username
      photo_url
    }
    company {
      id
      logo
      slug
      name
    }
  }
}
    `;

/**
 * __useReviewsFeedQuery__
 *
 * To run a query within a React component, call `useReviewsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReviewsFeedQuery(baseOptions?: Apollo.QueryHookOptions<ReviewsFeedQuery, ReviewsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsFeedQuery, ReviewsFeedQueryVariables>(ReviewsFeedDocument, options);
      }
export function useReviewsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsFeedQuery, ReviewsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsFeedQuery, ReviewsFeedQueryVariables>(ReviewsFeedDocument, options);
        }
export type ReviewsFeedQueryHookResult = ReturnType<typeof useReviewsFeedQuery>;
export type ReviewsFeedLazyQueryHookResult = ReturnType<typeof useReviewsFeedLazyQuery>;
export type ReviewsFeedQueryResult = Apollo.QueryResult<ReviewsFeedQuery, ReviewsFeedQueryVariables>;
export const AllReviewsCountDocument = gql`
    query AllReviewsCount {
  reviews_aggregated(filter: {status: {_eq: "published"}}) {
    count {
      id
    }
  }
}
    `;

/**
 * __useAllReviewsCountQuery__
 *
 * To run a query within a React component, call `useAllReviewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReviewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReviewsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllReviewsCountQuery(baseOptions?: Apollo.QueryHookOptions<AllReviewsCountQuery, AllReviewsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllReviewsCountQuery, AllReviewsCountQueryVariables>(AllReviewsCountDocument, options);
      }
export function useAllReviewsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllReviewsCountQuery, AllReviewsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllReviewsCountQuery, AllReviewsCountQueryVariables>(AllReviewsCountDocument, options);
        }
export type AllReviewsCountQueryHookResult = ReturnType<typeof useAllReviewsCountQuery>;
export type AllReviewsCountLazyQueryHookResult = ReturnType<typeof useAllReviewsCountLazyQuery>;
export type AllReviewsCountQueryResult = Apollo.QueryResult<AllReviewsCountQuery, AllReviewsCountQueryVariables>;
export const ReviewsCountBetweenDatesDocument = gql`
    query ReviewsCountBetweenDates($startDate: GraphQLStringOrFloat, $endDate: GraphQLStringOrFloat) {
  reviews_aggregated(
    filter: {status: {_eq: "published"}, date_created: {_between: [$startDate, $endDate]}}
  ) {
    count {
      id
    }
  }
}
    `;

/**
 * __useReviewsCountBetweenDatesQuery__
 *
 * To run a query within a React component, call `useReviewsCountBetweenDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsCountBetweenDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsCountBetweenDatesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useReviewsCountBetweenDatesQuery(baseOptions?: Apollo.QueryHookOptions<ReviewsCountBetweenDatesQuery, ReviewsCountBetweenDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsCountBetweenDatesQuery, ReviewsCountBetweenDatesQueryVariables>(ReviewsCountBetweenDatesDocument, options);
      }
export function useReviewsCountBetweenDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsCountBetweenDatesQuery, ReviewsCountBetweenDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsCountBetweenDatesQuery, ReviewsCountBetweenDatesQueryVariables>(ReviewsCountBetweenDatesDocument, options);
        }
export type ReviewsCountBetweenDatesQueryHookResult = ReturnType<typeof useReviewsCountBetweenDatesQuery>;
export type ReviewsCountBetweenDatesLazyQueryHookResult = ReturnType<typeof useReviewsCountBetweenDatesLazyQuery>;
export type ReviewsCountBetweenDatesQueryResult = Apollo.QueryResult<ReviewsCountBetweenDatesQuery, ReviewsCountBetweenDatesQueryVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($data: create_reviews_input!) {
  create_reviews_item(data: $data) {
    id
    text
  }
}
    `;
export type CreateReviewMutationFn = Apollo.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($data: create_comments_input!) {
  create_comments_item(data: $data) {
    id
    text
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const AddCommentLikeDocument = gql`
    mutation AddCommentLike($data: create_comments_likes_input!) {
  create_comments_likes_item(data: $data) {
    id
    value
  }
}
    `;
export type AddCommentLikeMutationFn = Apollo.MutationFunction<AddCommentLikeMutation, AddCommentLikeMutationVariables>;

/**
 * __useAddCommentLikeMutation__
 *
 * To run a mutation, you first call `useAddCommentLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentLikeMutation, { data, loading, error }] = useAddCommentLikeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCommentLikeMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentLikeMutation, AddCommentLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentLikeMutation, AddCommentLikeMutationVariables>(AddCommentLikeDocument, options);
      }
export type AddCommentLikeMutationHookResult = ReturnType<typeof useAddCommentLikeMutation>;
export type AddCommentLikeMutationResult = Apollo.MutationResult<AddCommentLikeMutation>;
export type AddCommentLikeMutationOptions = Apollo.BaseMutationOptions<AddCommentLikeMutation, AddCommentLikeMutationVariables>;
export const AddReviewLikeDocument = gql`
    mutation AddReviewLike($data: create_reviews_likes_input!) {
  create_reviews_likes_item(data: $data) {
    id
    value
  }
}
    `;
export type AddReviewLikeMutationFn = Apollo.MutationFunction<AddReviewLikeMutation, AddReviewLikeMutationVariables>;

/**
 * __useAddReviewLikeMutation__
 *
 * To run a mutation, you first call `useAddReviewLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReviewLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReviewLikeMutation, { data, loading, error }] = useAddReviewLikeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddReviewLikeMutation(baseOptions?: Apollo.MutationHookOptions<AddReviewLikeMutation, AddReviewLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReviewLikeMutation, AddReviewLikeMutationVariables>(AddReviewLikeDocument, options);
      }
export type AddReviewLikeMutationHookResult = ReturnType<typeof useAddReviewLikeMutation>;
export type AddReviewLikeMutationResult = Apollo.MutationResult<AddReviewLikeMutation>;
export type AddReviewLikeMutationOptions = Apollo.BaseMutationOptions<AddReviewLikeMutation, AddReviewLikeMutationVariables>;